
import { System } from '@/modules/system';
import $app from '@/plugins/modules'
import { Component, Vue } from 'vue-property-decorator'
import { ICuringPlan, IPatient } from '../types';

@Component
export default class CurePlanPrint extends Vue {
  system = $app.module(System);

  item: ICuringPlan | null = null;
  patient: IPatient | null = null;
  doctor: number | null = null;
  thera = true;
  loading = false;
  doc = this.documents[0]

  get user() {
    return this.system.$store.getCurrentUser
  }

  get doctorName(): string {
    return this.doctor ? $app.label('DoctorsGlossary', this.doctor) : ''
  }

  get manipulations() {
    return this.item && this.item.manipulations
      ? this.item.manipulations.filter(m => m.state !== 'C')
      : []
  }

  get total(): Number {
    let tot = 0
    this.manipulations.forEach(m => { tot += m.price || 0 })
    return tot
  }

  get documents(): Array<string> {
    return [
      'Pacienta saskaņotā piekrīšana izmeklēšanai, ārstēšanai un pakalpojumu apmaksai',
      'Pacienta apliecinājums par pakalpojuma saņemšanu'
    ]
  }

  get docindex() {
    return this.documents.indexOf(this.doc) || 0
  }

  async load() {
    this.loading = true;
    try {
      this.patient = await $app.get('/api/patients/' + this.$route.query.patient);
      if (this.patient) {
        this.item = this.patient.plans.find(p => p.id === Number(this.$route.query.id)) || null
        if (this.item) {
          this.item.manipulations = await $app.get('/api/patients/' + this.patient.patientId + '/curings/' + this.item.id + '/manipulations');
          this.doctor = this.item.author;
        }
      }
    } catch (err) {
      $app.pushError(err);
      throw err;
    }
    this.loading = false;
  }

  get background(): string {
    return this.user.background || ''
  }

  mounted() {
    this.load();
  }
}
